import React, { createContext, useState, useContext } from "react";

const DiffDaysPriceContext = createContext();

export const DiffDaysPriceProvider = ({ children }) => {
  const [roomNo, setRoomNo] = useState("");
  const [numberValue, setNumberValue] = useState(0);
  const [adultCount, setAdultCount] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [roomCount, setRoomCount] = useState(0);

  return (
    <DiffDaysPriceContext.Provider
      value={{
        roomNo,
        setRoomNo,
        numberValue,
        setNumberValue,
        adultCount,
        setAdultCount,
        childrenCount,
        setChildrenCount,
        roomCount,
        setRoomCount,
      }}
    >
      {children}
    </DiffDaysPriceContext.Provider>
  );
};

export const useNumber = () => useContext(DiffDaysPriceContext);
