import React from "react";
import "./TermsOfUse.css";

const TermsOfUse = () => {
  return (
    <section className=" p-8">
      <div>
        <h1 className=" fnt head">Terms of Use</h1>
        <p className="fnt headCnt my-2 font-medium">Apnu Ghar Makku Homestay</p>

        <p className="fnt headCnt mb-2 ">
          <span className=" font-medium">Location: </span>Makkumath,
          Rudraprayag, Ukhimath - 246419
        </p>
        <p className="fnt  mb-2 font-normal ">
          Welcome to Apnu Ghar Makku Homestay. By accessing or using our website
          and services, you agree to comply with and be bound by the following
          terms and conditions. Please read these terms carefully before using
          our website.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">1. Acceptance of Terms</h3>
        <p className="fnt pSubHead mx-10">
          By using our website, you acknowledge that you have read, understood,
          and agree to be bound by these Terms of Use, as well as our Privacy
          Policy. If you do not agree with these terms, please do not use our
          website.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">2. Changes to Terms</h3>
        <p className="fnt pSubHead mx-10">
          We reserve the right to modify these Terms of Use at any time without
          prior notice. Any changes will be effective immediately upon posting
          on our website. Your continued use of our website after changes have
          been posted constitutes your acceptance of the revised terms.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">3. Use of the Website</h3>
        <p className="fnt pSubHead mx-10">
          You agree to use our website for lawful purposes only. You must not
          use our website:
        </p>
        <ul className="mx-20 my-2">
          <li className="fnt liCnt  list-disc">
            To engage in any fraudulent, illegal, or unauthorized activities.
          </li>
          <li className="fnt liCnt  list-disc">
            To interfere with or disrupt the operation of our website or
            servers.
          </li>
          <li className="fnt liCnt  list-disc">
            To collect or harvest personal information of other users without
            their consent.
          </li>
        </ul>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">4. Booking and Reservations</h3>
        <p className="fnt pSubHead mx-10">
          When making a reservation through our website, you agree to provide
          accurate and complete information. You are responsible for maintaining
          the confidentiality of your booking details and for all activities
          that occur under your booking.
        </p>
      </div>
      <div>
        <h3 className="fnt  head3 mt-8 mb-2">5. Payments</h3>
        <p className="fnt pSubHead mx-10">
          All payments for bookings must be made through our designated payment
          methods. You agree to provide valid payment information and authorize
          us to charge the total amount of your booking. We reserve the right to
          cancel your reservation if your payment is declined or if you provide
          invalid payment information.
        </p>
      </div>
      <div>
        <h3 className="fnt  head3 mt-8 mb-2">6. Cancellation and Refunds</h3>
        <p className="fnt pSubHead mx-10">
          Our cancellation and refund policies are outlined in our Booking
          Policy. By making a reservation, you agree to comply with these
          policies. Please review the Booking Policy for detailed information on
          cancellations, refunds, and changes to your reservation.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">7. Intellectual Property</h3>
        <p className="fnt pSubHead mx-10">
          All content on our website, including text, graphics, logos, images,
          and software, is the property of Apnu Ghar Makku Homestay or its
          licensors and is protected by intellectual property laws. You may not
          reproduce, distribute, modify, or create derivative works based on any
          content from our website without our prior written consent.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">8. Limitation of Liability</h3>
        <p className="fnt pSubHead mx-10">
          To the fullest extent permitted by law, Apnu Ghar Makku Homestay shall
          not be liable for any direct, indirect, incidental, consequential, or
          punitive damages arising out of your use of our website or services.
          This includes, but is not limited to, damages for loss of profits,
          data, or other intangible losses.
        </p>
      </div>
      <div>
        <h3 className="fnt  head3 mt-8 mb-2">9. Indemnification</h3>
        <p className="fnt pSubHead mx-10">
          You agree to indemnify and hold harmless Apnu Ghar Makku Homestay, its
          officers, directors, employees, and agents from any claims,
          liabilities, damages, losses, and expenses, including legal fees,
          arising out of or in any way connected with your use of our website or
          services, your violation of these Terms of Use, or your violation of
          any rights of another.
        </p>
      </div>
      <div>
        <h3 className="fnt  head3 mt-8 mb-2">10. Governing Law</h3>
        <p className="fnt pSubHead mx-10">
          These Terms of Use shall be governed by and construed in accordance
          with the laws of [Your Country/State], without regard to its conflict
          of law principles. Any disputes arising out of or relating to these
          terms shall be resolved exclusively in the courts located in [Your
          Jurisdiction].
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">11. Contact Us</h3>
        <p className="fnt pSubHead mx-10 mb-4">
          If you have any questions or concerns about these Terms of Use, please
          contact us at:
        </p>
        <p className="fnt pSubSubHead mx-10">Apnu Ghar Makku Homestay</p>
        <p className="fnt pSubSubHead mx-10">
          Makkumath, Rudraprayag, Ukhimath - 246419
        </p>
        <p className="fnt pSubSubHead mx-10">
          Email: pranjalmaithani81822@gmail.com
        </p>
        <p className="fnt pSubSubHead mx-10">Phone: 9258902271</p>
      </div>
    </section>
  );
};

export default TermsOfUse;
