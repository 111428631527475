import React, { useState } from "react";

const Contact = () => {
  const [userData, setUserData] = useState({
    fullName: "",
    totalPerson: "1",
    totalRoom: "1",
    phone: "",
    email: "",
    address: "",
    city: "",
    state: "",
  });
  const postUserData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const {
      fullName,
      totalPerson,
      totalRoom,
      phone,
      email,
      address,
      city,
      state,
    } = userData;
    if (
      fullName &&
      totalPerson &&
      totalRoom &&
      phone &&
      email &&
      address &&
      city &&
      state
    ) {
      const res = await fetch(
        "https://apnu-ghar-makku-default-rtdb.firebaseio.com/bookingData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            totalPerson,
            totalRoom,
            phone,
            email,
            address,
            city,
            state,
          }),
        }
      );
      if (res) {
        setUserData({
          fullName: "",
          totalPerson: "1",
          totalRoom: "1",
          phone: "",
          email: "",
          address: "",
          city: "",
          state: "",
        });
        alert("Your reservation has been successfully submited.");
      } else {
        alert("Please fill all the fields in the form");
      }
    } else {
      alert("Please fill all the fields in the form");
    }
  };

  const onCancel = () => {
    let cancel = window.confirm("Are you sure you want to cancel it");
    if (cancel) {
      setUserData({
        fullName: "",
        totalPerson: "1",
        totalRoom: "1",
        phone: "",
        email: "",
        address: "",
        city: "",
        state: "",
      });
    }
  };

  return (
    <div className=" px-8 py-4">
      <form>
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">
              Reserve Your Room now!
            </h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">
              Provide valid name and mobile number.
            </p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label
                  for="fullName"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Full Name
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    autocomplete="on"
                    value={userData.fullName}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="totalPerson"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  No. of Person
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="totalPerson"
                    id="totalPerson"
                    autocomplete="off"
                    min="1"
                    defaultValue="1"
                    value={userData.totalPerson}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="totalRoom"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  No. of Rooms
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="totalRoom"
                    id="totalRoom"
                    autocomplete="off"
                    max="6"
                    min="1"
                    defaultValue="1"
                    value={userData.totalRoom}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="phone"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone Number
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="phone"
                    id="phone"
                    autocomplete="phone"
                    value={userData.phone}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="col-span-full">
                <label
                  for="email"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Email
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="email"
                    id="email"
                    autocomplete="email"
                    value={userData.email}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-2 sm:col-start-1">
                <label
                  for="address"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Address
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="address"
                    id="address"
                    autocomplete="on"
                    value={userData.address}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="city"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  City
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="city"
                    id="city"
                    autocomplete="on"
                    value={userData.city}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-2">
                <label
                  for="state"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  State
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="state"
                    id="state"
                    autocomplete="on"
                    value={userData.state}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
