import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { useNumber } from "../../hooks/DiffDaysPrice";

const Book = () => {
  const location = useLocation();
  const room = location.state?.room;

  const { numberValue, adultCount, childrenCount, roomCount } = useNumber();

  const [userData, setUserData] = useState({
    fullName: "",
    adult: adultCount,
    children: childrenCount,
    totalRooms: roomCount,
    phoneNo: "",
    totalNightStays: numberValue,
  });
  const postUserData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { fullName, adult, children, totalRooms, phoneNo, totalNightStays } =
      userData;
    if (
      fullName &&
      adult &&
      children &&
      totalRooms &&
      phoneNo &&
      totalNightStays
    ) {
      const res = await fetch(
        `https://apnu-ghar-makku-default-rtdb.firebaseio.com/${room}BookingData.json`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            fullName,
            adult,
            children,
            totalRooms,
            phoneNo,
            totalNightStays,
          }),
        }
      );
      if (res) {
        setUserData({
          fullName: "",
          adult: "1",
          children: "0",
          totalRooms: "1",
          phoneNo: "",
          totalNightStays: "1",
        });
        alert("Your booking has been successfully submited.");
      } else {
        alert("Please fill all the fields in the form");
      }
    } else {
      alert("Please fill all the fields in the form");
    }
  };

  const onCancel = () => {
    let cancel = window.confirm("Are you sure you want to cancel it");
    if (cancel) {
      setUserData({
        fullName: "",
        adult: "1",
        children: "0",
        totalRooms: "1",
        phoneNo: "",
        totalNightStays: "",
      });
    }
  };

  return (
    <div className=" px-8 py-4">
      <form>
        <div class="space-y-12">
          <div class="border-b border-gray-900/10 pb-12">
            <h2 class="text-base font-semibold leading-7 text-gray-900">
              {room} Booking Information
            </h2>
            <p class="mt-1 text-sm leading-6 text-gray-600">
              Provide valid name and mobile number.
            </p>

            <div class="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div class="sm:col-span-3">
                <label
                  for="fullName"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Full Name
                </label>
                <div class="mt-2">
                  <input
                    type="text"
                    name="fullName"
                    id="fullName"
                    autocomplete="on"
                    value={userData.fullName}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="adult"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Total Adults
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="adult"
                    id="adult"
                    autocomplete="off"
                    min="1"
                    defaultValue="1"
                    value={userData.adult}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="children"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Total Children
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="children"
                    id="children"
                    autocomplete="off"
                    min="0"
                    defaultValue="1"
                    value={userData.children}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="totalRooms"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Total Rooms
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="totalRooms"
                    id="totalRooms"
                    defaultValue="1"
                    value={userData.totalRooms}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
              <div class="sm:col-span-3">
                <label
                  for="phoneNo"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Phone no
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="phoneNo"
                    id="phoneNo"
                    autocomplete="on"
                    value={userData.phoneNo}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>

              <div class="sm:col-span-3">
                <label
                  for="totalNightStays"
                  class="block text-sm font-medium leading-6 text-gray-900"
                >
                  Total Night Stays
                </label>
                <div class="mt-2">
                  <input
                    type="number"
                    name="totalNightStays"
                    id="totalNightStays"
                    autocomplete="off"
                    value={userData.totalNightStays}
                    onChange={postUserData}
                    class="block w-full rounded-md border-0 py-1.5 px-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            class="text-sm font-semibold leading-6 text-gray-900"
            onClick={onCancel}
          >
            Cancel
          </button>
          <button
            type="submit"
            class="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            onClick={handleSubmit}
          >
            Apply
          </button>
        </div>
      </form>
    </div>
  );
};

export default Book;
