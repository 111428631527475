import { useState } from "react";
import "./MailList.css";

const MailList = () => {
  const [userMail, setUserMail] = useState({
    email: "",
  });
  const postUserMail = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setUserMail({ ...userMail, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { email } = userMail;
    if (email) {
      const res = await fetch(
        "https://apnu-ghar-makku-default-rtdb.firebaseio.com/emailData.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            email,
          }),
        }
      );
      if (res) {
        setUserMail({
          email: "",
        });
        alert("Your email has been successfully submited.");
      } else {
        alert("Please fill a valid email");
      }
    } else {
      alert("Please fill a valid email");
    }
  };
  return (
    <div className="mail">
      <span className="mailDesc">Subscribe to Apnu Ghar Homestay</span>
      <div className="mailInputContainer">
        <input
          type="email"
          placeholder="Your Email"
          name="email"
          id="email"
          autoComplete="email"
          value={userMail.email}
          onChange={postUserMail}
        />
        <button onClick={handleSubmit}>Subscribe</button>
      </div>
    </div>
  );
};

export default MailList;
