import React, { useState } from "react";
import { Link } from "react-router-dom";
import NavLinks from "./NavLinks";
import Apnu_ghar_logo from "../../assets/img/APNU_GHAR_MAKKU-logo2.png";
import { IoMenu } from "react-icons/io5";

const Navb = () => {
  const [open, setOpen] = useState(false);
  return (
    <nav className=" bg-main z-50">
      <div className="flex  items-center font-medium justify-around">
        <div className="z-50 p-5 md:w-auto w-full flex items-center  justify-between">
          <img
            src={Apnu_ghar_logo}
            alt="logo"
            className="md:cursor-pointer h-14 "
          />
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon
              name={`${open ? "close" : "menu"}`}
              style={{ color: "white" }}
              size="large"
            ></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8 font-[Poppins]">
          <li>
            <Link
              to="/"
              className="py-7 text-white px-3 inline-block hover:text-hov"
            >
              Home
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link
              to="/about"
              className="py-7 text-white px-3 inline-block hover:text-hov"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="py-7 text-white px-3 inline-block hover:text-hov"
            >
              Contact Us
            </Link>
          </li>
        </ul>

        {/* Mobile nav */}
        <ul
          className={`
        md:hidden bg-main z-30 absolute flex flex-col w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <li>
            <Link
              to="/"
              className="py-7 text-white px-3 inline-block hover:text-hov"
              onClick={() => setOpen(!open)}
            >
              Home
            </Link>
          </li>
          <NavLinks />

          <li>
            <Link
              to="/about"
              className="py-7 px-3 text-white inline-block hover:text-hov"
              onClick={() => setOpen(!open)}
            >
              About us
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className="py-7 px-3 text-white inline-block hover:text-hov"
              onClick={() => setOpen(!open)}
            >
              Contact Us
            </Link>
          </li>
          {/* <div className="py-5">
            <Button />
          </div> */}
        </ul>
      </div>
    </nav>
  );
};

export default Navb;
