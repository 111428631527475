import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";

import About from "./pages/about/About";
import Contact from "./pages/contact/Contact";
import Room2 from "./pages/room2/Room2";
import Navb from "./components/navb/Navb";
import Footer from "./components/footer/Footer";
import Room3 from "./pages/room3/Room3";
import SearchItem from "./components/searchItem/SearchItem";
import Hotel from "./pages/hotel/Hotel";
import List from "./pages/list/List";
import Book from "./pages/book/Book";
import PrivacyPolicy from "./pages/privacyPolicy/PrivacyPolicy";
import TermsOfUse from "./pages/termsOfUse/TermsOfUse";
import Room4 from "./pages/room4/Room4";

function App() {
  return (
    <>
      <BrowserRouter>
        <Navb />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/termofuse" element={<TermsOfUse />} />
          <Route path="/room2" element={<Room2 />} />
          <Route path="/room3" element={<Room3 />} />
          <Route path="/room4" element={<Room4 />} />
          <Route path="/search" element={<SearchItem />} />
          <Route path="/hotel" element={<Hotel />} />
          <Route path="/list" element={<List />} />
          <Route path="/booking" element={<Book />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
