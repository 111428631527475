import React from "react";
import Reviews from "../../components/reviews/Reviews";
import Header from "../../components/header/Header";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import img1 from "../../assets/img/room2/room2-1.jpg";
import img2 from "../../assets/img/room2/room2-2.jpg";
import img3 from "../../assets/img/room2/room2-3.jpg";
import img4 from "../../assets/img/room2/room2-4.jpg";
import img5 from "../../assets/img/room1/room1-5.jpg";
import img6 from "../../assets/img/room1/room1-6.jpg";
import img7 from "../../assets/img/room1/room1-7.jpg";
import img8 from "../../assets/img/room3/room3-2.jpg";
import img9 from "../../assets/img/room3/room3-4.jpg";
import img10 from "../../assets/img/room3/room3-6.jpg";
import MailList from "../../components/mailList/MailList";

const Room2 = () => {
  return (
    <>
      <div className="main-container">
        <Header
          desc={`Spacious and comfortable double bed with plush pillows and warm blankets.
              En-suite bathroom with fresh towels and basic toiletries.
              Panoramic views of the majestic Himalayan peaks from your private balcony (optional).
              Indulge in Local Delights:
              Start your day with a delightful breakfast prepared with fresh local ingredients.
              Savor delicious home-cooked meals for lunch and dinner, showcasing traditional Garhwali cuisine. (Optional: Specify dietary restrictions)
              Embrace the Surroundings: 
              Explore the breathtaking Chopta Valley, known for its meadows, forests, and scenic beauty.`}
          room="Room 2 of"
        />
        <ImageSlider
          title="Room 2 Gallery"
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
          img5={img5}
          img6={img6}
          img7={img7}
          img8={img8}
          img9={img9}
          img10={img10}
        />

        <MailList />
        <Reviews
          review1="Apnu Ghar Homestay was the ideal getaway for my family's trip to Chopta Valley. The location in Makkumath was peaceful and offered stunning mountain views. The hosts were incredibly welcoming and treated us like family. The food was delicious, homemade with fresh local ingredients. We especially enjoyed the evenings by the bonfire, sharing stories under the starry sky. It was a truly memorable experience, and we can't wait to return!"
          guest1="Sarah Jones, Delhi"
          review2="I stayed at Apnu Ghar Homestay solo for a week of trekking in the Chopta Valley. The homestay provided a warm and comfortable haven after long days exploring the mountains. The room was clean and cozy, and the bed was incredibly comfortable. The hosts went above and beyond to ensure I had everything I needed, from delicious meals to helpful advice on local trails. I felt safe, relaxed, and truly at home. Thank you, Apnu Ghar!"
          guest2="Chetan Dhami"
        />
      </div>
    </>
  );
};

export default Room2;
