import "./hotel.css";
import Header from "../../components/header/Header";
import MailList from "../../components/mailList/MailList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleXmark,
  faLocationDot,
  faSolid,
} from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { FaCircleArrowLeft, FaCircleArrowRight } from "react-icons/fa6";
import { RxCrossCircled } from "react-icons/rx";
import { useLocation, useNavigate } from "react-router-dom";
import { useNumber } from "../../hooks/DiffDaysPrice";

const Hotel = () => {
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const room = location.state?.room;
  const daysPrice = location.state?.daysDiffPrice;
  const { numberValue, adultCount } = useNumber();

  const photos = [
    {
      src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEgccIxHmfG1XhGmciD15SKBwNG8OXyZ-zNE84k0jmC4nzR2F6-CEHNsBa9RMYK7W-0ogHxCl9bU26938wYywA4M7VXr6CAN0HFVUnq3_cWcyZAbkN9Dsr5Xog8Bqd6I0MNue_ZL8DuJDDd1uaW0m8dgN23r6Aj-EzKpY1zwE_zVAwDjHqY91Msryi18L0c8/s320/room1-6.jpg",
    },
    {
      src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjaqzeD9j92XqJOUiZFky_2dObdxx7ZbdEkU0YDGV5dm-2BLeIPQ97wvuRaeEuoykAoXzr8X_Ctv0W69_Neg2b4L5E7GSVZtGK2BDeecYfnstUBfNxfEPM-FkjP7Aq6xA546q2UV_B-k7EIW_nAByMMUz-N4bwrt8wT9eogVjdQ_JU4-Gop9NYe_6q8D8Dc/s320/room1-5.jpg",
    },
    {
      src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEiIn6nTwpWrDHoDSZttfCAa1mp_0rczF60-rJuHGM2Sk0gkKtH8ak9W5vKnbdpx_QY6QafRTpsKWbiWDyWwii_GPxn10FiKW3sEBZz8GURGKmbdoocJZfAEJoCZddyAzH161_ONDCNU7KZTAB2ZgPJvKNUmCuRnx6G19NeXOuhd2ROYHwmtoVeBqEWcDVN4/s320/room1-4.jpg",
    },
    {
      src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh-kAwgvWzdOwHLsuJobk6-AXY8gxefubuQkvew1IgU1DJ9joyAjFxP6dOfhe3DQ1O08jVp8MeRjVm6WyGvWWLf3dZVRw1mx-ZQWZsgswfDJyODHsrXlH5_T2ieBEZNbZdf3GoyonnQ9F1GSh0qHrP_-Onupcd5Q50g5xLKMCQgOQSxkAxlt2QNQf0oF49r/s320/room1-3.jpg",
    },
    {
      src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEh3sny2M4qvyUmSFHNWpp0iEWAxOIakof_ehy2_A4LO1gcb6nySTiT_cgLPX8nHDohU0fVhgey2KuA-ospTEFMfDahS_7Gje2LZi4K0YBdqYUNzPpPb3G_ZM3zWMTP02eEluerxmt4Amfc_iY47-vE59ym6BNbny227-Bn-Y_VB7q_xEE8hSnXziNa2Ev_j/s320/room1-2.jpg",
    },
    {
      src: "https://blogger.googleusercontent.com/img/b/R29vZ2xl/AVvXsEjZdxaA7KV1t5fbAv8v_730EWVTJIUNg4_2pRk1AlofAFZ4UfKytXuNp_Lf_kkCDL23XHK57fyDdF_hZB2ncYl8QW86Mdm_R9NpRtzEE83GDBrd1M1UIMs8DygAILBEqQK0zIiLpaP5IwwuryWB9RZpyrlZ0oZ_P5Exdy9QZFAxi0w16TAGVERCn9UeSNND/s320/room1-1.jpg",
    },
  ];

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber = slideNumber === 0 ? 5 : slideNumber - 1;
    } else {
      newSlideNumber = slideNumber === 5 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  return (
    <div>
      <Header type="list" />
      <div className="hotelContainer">
        {open && (
          <div className="slider">
            <RxCrossCircled className="close" onClick={() => setOpen(false)} />

            <FaCircleArrowLeft
              className="arrow"
              onClick={() => handleMove("l")}
            />
            <div className="sliderWrapper">
              <img src={photos[slideNumber].src} alt="" className="sliderImg" />
            </div>

            <FaCircleArrowRight
              className="arrow"
              onClick={() => handleMove("r")}
            />
          </div>
        )}
        <div className="hotelWrapper">
          <h1 className="hotelTitle">{room}</h1>
          <div className="hotelAddress">
            <FontAwesomeIcon icon={faLocationDot} />
            <span> Makkhumath, Rudraprayag, Ukhimath - 246419</span>
          </div>
          <span className="hotelDistance">
            Excellent location – 100m from station
          </span>
          <span className="hotelMeal">
            Book a stay at this property and get a free morning breakfast
          </span>
          <div className="hotelImages">
            {photos.map((photo, i) => (
              <div className="hotelImgWrapper" key={i}>
                <img
                  onClick={() => handleOpen(i)}
                  src={photo.src}
                  alt=""
                  className="hotelImg"
                />
              </div>
            ))}
          </div>
          <div className="hotelDetails">
            <div className="hotelDetailsTexts">
              <h1 className="hotelTitle">Stay in the heart of Makku</h1>
              <p className="hotelDesc">
                Nestled in the heart of our vibrant city, Apnu Ghar Home Stay
                offers a perfect blend of modern comfort and traditional
                hospitality. Whether you're here for a leisurely getaway or a
                business trip, our warm and inviting ambiance ensures a
                memorable stay. Each room is meticulously designed to provide a
                serene and homely atmosphere, complete with all the amenities
                you need for a relaxing experience. Our friendly staff is
                dedicated to making you feel at home, offering personalized
                service with a smile. Come and experience the charm and comfort
                of Apnu Ghar Home Stay – where every guest is treated like
                family.
              </p>
            </div>
            <div className="hotelDetailsPrice">
              <h1>Perfect for a stay!</h1>
              <span>
                Located in the real heart of chopta, this property has an
                excellent location score of 9.8!
              </span>
              <h2>
                <b>₹ {numberValue * 1700}</b> ({numberValue} night)
              </h2>
              <button
                onClick={() =>
                  navigate("/booking", {
                    state: {
                      room: `${room}`,
                    },
                  })
                }
              >
                Reserve or Book Now!
              </button>
            </div>
          </div>
        </div>
        <MailList />
      </div>
    </div>
  );
};

export default Hotel;
