import React from "react";
import Reviews from "../../components/reviews/Reviews";
import Header from "../../components/header/Header";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import img1 from "../../assets/img/room3/room3-1.jpg";
import img2 from "../../assets/img/room3/room3-2.jpg";
import img3 from "../../assets/img/room3/room3-3.jpg";
import img4 from "../../assets/img/room3/room3-4.jpg";
import img5 from "../../assets/img/room3/room3-5.jpg";
import img6 from "../../assets/img/room3/room3-6.jpg";
import img7 from "../../assets/img/room1/room1-7.jpg";
import img8 from "../../assets/img/room3/room3-2.jpg";
import img9 from "../../assets/img/room3/room3-4.jpg";
import img10 from "../../assets/img/room3/room3-6.jpg";
import MailList from "../../components/mailList/MailList";

const Room3 = () => {
  return (
    <>
      <div className="main-container">
        <Header
          desc="Beyond your room, Apnu Ghar Homestay offers an array of shared facilities to enhance your stay:

            Hearty Home-cooked Meals: Savor delicious and authentic Garhwali cuisine prepared with fresh, local ingredients.
            Serene Common Area: Relax and unwind in the shared common area, perhaps with a good book or fellow travelers, while soaking in the captivating mountain views.
            Warm Hospitality: Experience the genuine warmth and hospitality of our family-run homestay. We're here to ensure your comfort and make your stay in Chopta Valley unforgettable.
            Book your double room at Apnu Ghar Homestay today and embark on a delightful journey into the heart of the Himalayas!"
          room="Room 3 of"
        />
        <ImageSlider
          title="Room 3 Gallery"
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
          img5={img5}
          img6={img6}
          img7={img7}
          img8={img8}
          img9={img9}
          img10={img10}
        />
        <MailList />

        <Reviews
          review1="Apnu Ghar Homestay offered so much more than just a place to sleep. Staying with a local family allowed us to experience the unique culture of the Uttarakhand region firsthand. We learned about traditional cooking from the host mother, participated in local ceremonies, and even practiced some Hindi conversation. It was an enriching experience that added a whole new dimension to our Himalayan adventure. Thank you, Apnu Ghar, for making our trip so special!"
          guest1="Deepak Baliyan, Dehradun"
          review2="As birdwatchers, Apnu Ghar Homestay was an absolute paradise. The balcony provided a fantastic vantage point for spotting various bird species, and the nearby forests were teeming with avian life. The hosts were knowledgeable about the local birdlife and were happy to share their expertise. They even helped us arrange a guided birding tour with a local expert. We were thrilled with the abundance and variety of birds we saw. Apnu Ghar is a must-stay for any nature enthusiast!"
          guest2="Manish Kashyap, Delhi"
        />
      </div>
    </>
  );
};

export default Room3;
