import { useNavigate } from "react-router-dom";
import "./searchItem.css";

const SearchItem = (props) => {
  const navigate = useNavigate();
  return (
    <div className="searchItem">
      <img src={props.src} alt="logo-image" className="siImg" />
      <div className="siDesc">
        <h1 className="siTitle">{props.title}</h1>
        <span className="siDistance">{props.distance} from station</span>
        <span className="siMeal">{props.meal}</span>
        <span className="siSubtitle">
          Studio Apartment with Geyser, Almirah, Fan & Electric Kettle
        </span>
        <span className="siFeatures">
          Entire studio • 1 bathroom • 1 full bed • 1 dining table
        </span>
        <span className="siCancelOp">Free cancellation </span>
        <span className="siCancelOpSubtitle">
          You can cancel later, so lock in this great price today!
        </span>
      </div>
      <div className="siDetails">
        <div className="siRating">
          <span>Excellent</span>
          <button>{props.rating}</button>
        </div>
        <div className="siDetailTexts">
          <span className="siPrice">{props.price}</span>
          <span className="siTaxOp">Includes taxes and fees</span>
          <button
            className="siCheckButton"
            onClick={() =>
              navigate("/hotel", {
                state: {
                  daysPrice: `${props.price}`,
                  room: `${props.title}`,
                },
              })
            }
          >
            See availability
          </button>
        </div>
      </div>
    </div>
  );
};

export default SearchItem;
