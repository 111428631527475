import React from "react";
import Reviews from "../../components/reviews/Reviews";
import Header from "../../components/header/Header";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import img1 from "../../assets/img/room4/room4-1.jpg";
import img2 from "../../assets/img/room4/room4-2.jpg";
import img3 from "../../assets/img/room4/room4-3.jpg";
import img4 from "../../assets/img/room4/room4-4.jpg";
import img5 from "../../assets/img/room4/room4-5.jpg";
import img6 from "../../assets/img/room3/room3-6.jpg";
import img7 from "../../assets/img/room1/room1-7.jpg";
import img8 from "../../assets/img/room3/room3-2.jpg";
import img9 from "../../assets/img/room3/room3-4.jpg";
import img10 from "../../assets/img/room3/room3-6.jpg";
import MailList from "../../components/mailList/MailList";

const Room4 = () => {
  return (
    <>
      <div className="main-container">
        <Header
          desc="Apnu Ghar Homestay: Double Room Paradise in Chopta Valley
            Escape to the serenity of the Himalayas with our inviting double room at Apnu Ghar Homestay in Makku, nestled amidst the breathtaking Chopta Valley. Unwind in a comfortable space designed for two, perfect for couples, friends, or solo travelers seeking a cozy retreat.

            Here's what awaits you in your double room:

            Cozy Comfort: Relax in a well-furnished room with a plush double bed draped in fresh linens, ensuring a restful night's sleep after your adventures.
            Captivating Views: Wake up to the majestic Himalayan panorama. Throw open your curtains and be greeted by the sight of snow-capped peaks and lush valleys, right from your window."
          room="Room 4 of"
        />
        <ImageSlider
          title="Room 4 Gallery"
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
          img5={img5}
          img6={img6}
          img7={img7}
          img8={img8}
          img9={img9}
          img10={img10}
        />
        <MailList />

        <Reviews
          review1="Apnu Ghar Homestay offered more than just a place to stay; it provided a cultural immersion experience. The hosts warmly welcomed us into their home and shared stories about their lives and the local traditions. We learned about Garhwali cuisine through their delicious cooking and enjoyed participating in some cultural activities. This homestay provided a unique and unforgettable experience."
          guest1="Emily & John (Cultural Enthusiasts)"
          review2="My stay at Apnu Ghar Homestay was like a breath of fresh air. The serene atmosphere and stunning views of the Himalayas helped me unwind and reconnect with nature. The peaceful surroundings offered a welcome escape from the stresses of daily life. The quiet nights and comfortable sleep allowed me to recharge and rejuvenate. I left feeling refreshed and inspired"
          guest2="Anika Malhotra"
        />
      </div>
    </>
  );
};

export default Room4;
