import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";

import { CiLocationOn } from "react-icons/ci";

import "./style.scss";
import { Link, useNavigate } from "react-router-dom";

const Footer = () => {
  const redirectAPTechEnterprise = () => {
    window.location.href = `https://aptechenterprise.in/`;
  };

  const handleFb = () => {
    const url = `https://www.facebook.com/profile.php?id=61562472081677`;
    window.open(url, "_blank");
  };

  const handleInsta = () => {
    const url = `https://www.instagram.com/apnughar_makku?igsh=Z2gwN2xzcjNnZzIy`;
    window.open(url, "_blank");
  };
  const navigate = useNavigate();
  return (
    <footer className="footer">
      <div className="contentWrapper">
        <ul className="menuItems">
          <li className="menuItem" onClick={() => navigate("/termofuse")}>
            Terms Of Use
          </li>
          <li className="menuItem" onClick={() => navigate("/privacypolicy")}>
            Privacy-Policy
          </li>
          <li className="menuItem" onClick={() => navigate("/about")}>
            About
          </li>
          {/* <li className="menuItem">Blog</li> */}
          {/* <li className="menuItem">FAQ</li> */}
        </ul>
        <div className="infoText">
          © 2024, APNU GHAR MAKKU. All rights reserved.
        </div>
        <p className=" mb-5">
          <span className=" text-sm font-medium">Created by : </span>{" "}
          <Link
            className="text-sm text-blue-300"
            onClick={redirectAPTechEnterprise}
          >
            AP Tech Enterprise
          </Link>
        </p>
        <div className=" my-4 w-full flex justify-around ">
          <div>
            <p className=" text-base hover:text-pinky ">
              <CiLocationOn className=" inline text-white" />{" "}
              <span>Address : </span>
              Makkhumath,
              <br /> Rudraprayag, Ukhimath - <br />
              246419
            </p>
            <ul>
              <li className=" py-2">
                <Link to="/" className="text-base hover:text-pinky ">
                  Home
                </Link>
              </li>
              <li className="py-2">
                <Link to="/about" className="text-base hover:text-pinky ">
                  About
                </Link>
              </li>
              <li className="py-2">
                <Link to="contact" className="text-base hover:text-pinky">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <ul className="text-base ">
              <li className="py-2 hover:text-pinky">
                <Link to="/">Room 1</Link>
              </li>
              <li className="py-2 hover:text-pinky">
                <Link to="/room2">Room 2</Link>
              </li>
              <li className="py-2 hover:text-pinky">
                <Link to="room3">Room 3</Link>
              </li>
              <li className="py-2 hover:text-pinky">
                <Link to="room4">Room 4</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="socialIcons">
          <span className="icon">
            <FaFacebookF onClick={handleFb} />
          </span>
          <span className="icon">
            <FaInstagram onClick={handleInsta} />
          </span>
          <span className="icon">
            <FaTwitter />
          </span>
          <span className="icon">
            <FaLinkedin />
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
