import Header from "../../components/header/Header";
import ImageSlider from "../../components/imageSlider/ImageSlider";
import Reviews from "../../components/reviews/Reviews";
import Location from "../../components/location/Location";
import "./home.css";
import Cards from "../../components/cards/Cards";
import StickyWhatsAppButton from "../../components/StickyWhatsAppButton/StickyWhatsAppButton";
import MailList from "../../components/mailList/MailList";

import img1 from "../../assets/img/room1/room1-1.jpg";
import img2 from "../../assets/img/room1/room1-2.jpg";
import img3 from "../../assets/img/room1/room1-3.jpg";
import img4 from "../../assets/img/room1/room1-4.jpg";
import img5 from "../../assets/img/room1/room1-5.jpg";
import img6 from "../../assets/img/room1/room1-6.jpg";
import img7 from "../../assets/img/room1/room1-7.jpg";
import img8 from "../../assets/img/room3/room3-2.jpg";
import img9 from "../../assets/img/room3/room3-4.jpg";
import img10 from "../../assets/img/room3/room3-6.jpg";

const Home = () => {
  return (
    <>
      <div className="main-container">
        <Header
          desc="Nestled in the heart of our vibrant city, Apnu Ghar Home Stay
              offers a perfect blend of modern comfort and traditional
              hospitality. Whether you're here for a leisurely getaway or a
              business trip, our warm and inviting ambiance ensures a memorable
              stay. Each room is meticulously designed to provide a serene and
              homely atmosphere, complete with all the amenities you need for a
              relaxing experience. Our friendly staff is dedicated to making you
              feel at home, offering personalized service with a smile. Come and
              experience the charm and comfort of Apnu Ghar Home Stay – where
              every guest is treated like family."
          room=""
        />
        <ImageSlider
          title="Apnu Ghar Gallery"
          img1={img1}
          img2={img2}
          img3={img3}
          img4={img4}
          img5={img5}
          img6={img6}
          img7={img7}
          img8={img8}
          img9={img9}
          img10={img10}
        />
        <Location />
        <Cards />
        <MailList />
        <Reviews
          review1="Apnu Ghar Home Stay offered more than just comfortable
                  accommodations; it provided an authentic experience of the
                  Garhwali culture and the magic of the Himalayas. It was a
                  place where I could truly unwind, reconnect with nature, and
                  create memories that will last a lifetime."
          guest1="Mohit Kumar"
          review2=" My recent stay at Apnu Ghar Home Stay in Chopta was an
                  unforgettable experience. Nestled amidst the breathtaking
                  beauty of the Himalayas, this homestay offered much more than
                  just a place to sleep. The warm hospitality of the hosts made
                  me feel right at home from the moment I arrived."
          guest2="Rakesh Shetty"
        />
      </div>
      <StickyWhatsAppButton />
    </>
  );
};

export default Home;
