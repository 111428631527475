export const links = [
  {
    name: "Rooms",
    submenu: true,
    sublinks: [
      {
        Head: "Select Room",
        sublink: [
          { name: "Room 1", link: "/" },
          { name: "Room 2", link: "/room2" },
          { name: "Room 3", link: "/room3" },
          { name: "Room 4", link: "/room4" },
        ],
      },
    ],
  },
];
