import "./List.css";
// import Navbar from "../../components/navbar/Navbar";
import Header from "../../components/header/Header";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { differenceInDays, format } from "date-fns";
import { DateRange } from "react-date-range";
import SearchItem from "../../components/searchItem/SearchItem";
import room1 from "../../assets/img/room1/room1-5.jpg";
import room2 from "../../assets/img/room3/room3-2.jpg";
import room3 from "../../assets/img/room3/room3-4.jpg";
import room4 from "../../assets/img/room4/room4-3.jpg";
import { useNumber } from "../../hooks/DiffDaysPrice";

const List = () => {
  const location = useLocation();
  console.log(location);
  const [date, setDate] = useState(location.state.dates);
  const [openDate, setOpenDate] = useState(false);
  const [options, setOptions] = useState(location.state.options);
  const [searchData, setSearchData] = useState({
    roomNo: "Room 1",
    adult: options.adult,
    children: options.children,
    totalRooms: options.room,
  });

  const postSaerchData = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    setSearchData({ ...searchData, [name]: value });
  };

  const daysDifference = differenceInDays(
    new Date(date[0].endDate),
    new Date(date[0].startDate)
  );

  const daysDiffPrice = daysDifference;

  const { setNumberValue, setAdultCount, setChildrenCount, setRoomCount } =
    useNumber();

  useEffect(() => {
    setNumberValue(daysDifference + 1);
    setAdultCount(searchData.adult);
    setChildrenCount(searchData.children);
    setRoomCount(searchData.totalRooms);
  });

  return (
    <div>
      <Header type="list" />
      <div className="listContainer">
        <div className="listWrapper">
          <div className="listSearch">
            <h1 className="lsTitle">Apnu Ghar Rooms List</h1>
            <div className="lsItem">
              <label>Room No.</label>
              <input
                placeholder="Room 1"
                type="text"
                name="roomNo"
                value={searchData.roomNo}
                onChange={postSaerchData}
              />
            </div>
            <div className="lsItem">
              <label>Check-in Date</label>
              <span onClick={() => setOpenDate(!openDate)}>{`${format(
                date[0].startDate,
                "MM/dd/yyyy"
              )} to ${format(date[0].endDate, "MM/dd/yyyy")}`}</span>
              {openDate && (
                <DateRange
                  onChange={(item) => setDate([item.selection])}
                  minDate={new Date()}
                  ranges={date}
                />
              )}
            </div>
            <div className="lsItem">
              <label>Options</label>
              <div className="lsOptions">
                <div className="lsOptionItem">
                  <span className="lsOptionText">Adult</span>
                  <input
                    type="number"
                    min={1}
                    className="lsOptionInput"
                    placeholder={options.adult}
                    name="adult"
                    value={searchData.adult}
                    onChange={postSaerchData}
                  />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">Children</span>
                  <input
                    type="number"
                    min={0}
                    className="lsOptionInput"
                    placeholder={options.children}
                    name="children"
                    value={searchData.children}
                    onChange={postSaerchData}
                  />
                </div>
                <div className="lsOptionItem">
                  <span className="lsOptionText">Room</span>
                  <input
                    type="number"
                    min={1}
                    className="lsOptionInput"
                    placeholder={options.room}
                    name="totalRooms"
                    value={searchData.totalRooms}
                    onChange={postSaerchData}
                  />
                </div>
              </div>
            </div>
            {/* <button>Search</button> */}
          </div>
          <div className="listResult">
            <SearchItem
              title="Room 1"
              src={room1}
              distance="100 m"
              meal="Free morning breakfast"
              rating={9.2}
              price="₹ 1700"
              daysDiffPrice={daysDiffPrice}
            />
            <SearchItem
              title="Room 2"
              src={room2}
              distance="100 m"
              meal="Free morning breakfast"
              rating={9.2}
              price="₹ 1700"
              daysDifference={daysDifference}
            />
            <SearchItem
              title="Room 3"
              src={room3}
              distance="100 m"
              meal="Free morning breakfast"
              rating={9.2}
              price="₹ 1700"
              daysDifference={daysDifference}
            />
            <SearchItem
              title="Room 4"
              src={room4}
              distance="100 m"
              meal="Free morning breakfast"
              rating={9.2}
              price="₹ 1700"
              daysDifference={daysDifference}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default List;
