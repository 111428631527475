import React from "react";
import "./PrivacyPolicy.css";

const PrivacyPolicy = () => {
  return (
    <section className=" p-8">
      <div>
        <h1 className=" fnt head">Privacy Policy</h1>
        <p className="fnt headCnt my-2 font-medium">Apnu Ghar Makku Homestay</p>

        <p className="fnt headCnt mb-2 ">
          <span className=" font-medium">Location: </span>Makkumath,
          Rudraprayag, Ukhimath - 246419
        </p>
        <p className="fnt  mb-2 font-normal ">
          Welcome to Apnu Ghar Makku Homestay. We respect your privacy and are
          committed to protecting your personal data. This privacy policy will
          inform you about how we look after your personal data when you visit
          our website or stay at our homestay.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">1. Information We Collect</h3>
        <p className="fnt pSubHead mx-10">
          We may collect, use, store, and transfer different kinds of personal
          data about you, including:
        </p>
        <ul className=" mx-20 my-2">
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Identity Data:</b> Name, date of birth,
            gender, and nationality.
          </li>
          <li className="fnt liCnt list-disc">
            <b className=" font-bold"> Contact Data:</b> Address, email address,
            phone numbers.
          </li>
          <li className="fnt liCnt list-disc">
            <b className=" font-bold">Booking Data:</b> Details about your
            reservation, including dates of stay, room preferences, and special
            requests.
          </li>
          <li className="fnt liCnt list-disc">
            <b className=" font-bold">Payment Data:</b> Payment card details and
            transaction information.
          </li>
          <li className="fnt liCnt list-disct">
            <b className=" font-bold">Technical Data:</b> IP address, browser
            type and version, time zone setting, browser plug-in types and
            versions, operating system and platform, and other technology on the
            devices you use to access this website.
          </li>
          <li className="fnt liCnt list-disc">
            <b className=" font-bold">Usage Data:</b> Information about how you
            use our website, products, and services.
          </li>
          <li className="fnt liCnt list-disc">
            <b className=" font-bold">Marketing and Communications Data:</b>{" "}
            Your preferences in receiving marketing from us and your
            communication preferences.
          </li>
        </ul>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">2. How We Use Your Information</h3>
        <p className="fnt pSubHead mx-10">
          We use your personal data for the following purposes:
        </p>
        <ul className="mx-20 my-2">
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">To manage your booking:</b> To process and
            confirm your reservations.
          </li>
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold"> To provide customer service:</b> To
            respond to your inquiries and fulfill your requests.
          </li>
          <li className="fnt">
            <b className=" font-bold">To improve our services:</b> To understand
            our customers' preferences and improve our offerings.
          </li>
          <li className="fnt">
            <b className=" font-bold">To send marketing communications:</b> To
            keep you informed about promotions, offers, and updates, where you
            have opted in to receive such communications.
          </li>
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">To comply with legal obligations:</b> To
            comply with applicable laws and regulations.
          </li>
        </ul>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">3. Sharing Your Information</h3>
        <p className="fnt pSubHead mx-10">
          We do not sell your personal data to third parties. We may share your
          personal data with:
        </p>
        <ul className="mx-20 my-2">
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Service Providers:</b> Third parties who
            provide services on our behalf, such as payment processors and IT
            service providers.
          </li>
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Legal Authorities:</b> If required by law
            or to protect our legal rights.
          </li>
        </ul>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">4. Data Security</h3>
        <p className="fnt pSubHead mx-10">
          We have implemented appropriate security measures to prevent your
          personal data from being accidentally lost, used, or accessed in an
          unauthorized way, altered, or disclosed.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">5. Your Rights</h3>
        <p className="fnt pSubHead mx-10">You have the right to:</p>
        <ul className="mx-20 my-2">
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Access Your Data:</b> Request a copy of
            the personal data we hold about you.
          </li>
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Correct Your Data:</b> Request correction
            of any inaccurate or incomplete data.
          </li>
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Delete Your Data:</b> Request deletion of
            your personal data where there is no good reason for us to continue
            processing it.
          </li>
          <li className="fnt liCnt  list-disc">
            <b className=" font-bold">Object to Processing:</b> Object to
            processing of your personal data where we are relying on a
            legitimate interest.
          </li>
        </ul>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">
          6. Changes to This Privacy Policy
        </h3>
        <p className="fnt pSubHead mx-10">
          We may update this privacy policy from time to time. We will notify
          you of any changes by posting the new privacy policy on this page.
        </p>
      </div>

      <div>
        <h3 className="fnt  head3 mt-8 mb-2">7. Contact Us</h3>
        <p className="fnt pSubHead mx-10 mb-4">
          If you have any questions about this privacy policy or our privacy
          practices, please contact us at:
        </p>
        <p className="fnt pSubSubHead mx-10">Apnu Ghar Makku Homestay</p>
        <p className="fnt pSubSubHead mx-10">
          Makkumath, Rudraprayag, Ukhimath - 246419
        </p>
        <p className="fnt pSubSubHead mx-10">
          Email: pranjalmaithani81822@gmail.com
        </p>
        <p className="fnt pSubSubHead mx-10">Phone: 9258902271</p>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
