import React from "react";
import {
  Grid,
  Card,
  CardContrnt,
  Typography,
  CardActions,
} from "@mui/material";
import Container from "@mui/material/Container";
import { famousPlaceData } from "../../Data";
import { CardActionArea } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";

const Cards = () => {
  const redirectToChandrashila = (webTitle) => {
    window.location.href = `https://en.wikipedia.org/wiki/${webTitle}`;
  };
  return (
    <>
      <Container maxWidth="lg">
        <Typography
          variant="h4"
          align="center"
          style={{ marginTop: "50px", fontWeight: 600 }}
        >
          Famous nearby places
        </Typography>
        <Grid container spacing={5} style={{ marginTop: "20px" }}>
          {famousPlaceData.map((result, index) => (
            <Grid item xs={12} sm={4} ms={4} key={index}>
              <Card
                sx={{ maxWidth: 345 }}
                style={{ padding: "10px", marginBottom: "30px" }}
              >
                <CardActionArea>
                  <CardMedia
                    component="img"
                    height="140"
                    image={result.img}
                    alt="green iguana"
                    style={{ borderRadius: "5px" }}
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {result.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {result.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <CardActions>
                  <Button
                    variant="contained"
                    size="medium"
                    onClick={() => redirectToChandrashila(result.webTitle)}
                  >
                    Read more
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default Cards;
