import React from "react";
import "./Location.css";

const Location = () => {
  return (
    <div className="location_container">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13753.397363604976!2d79.1350781!3d30.4828625!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3908330044f31017%3A0x9b34407a3f01cdb9!2sApnu%20Ghar%20Home%20Stay!5e0!3m2!1sen!2sin!4v1717758828766!5m2!1sen!2sin"
        width="600"
        height="450"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
  );
};

export default Location;
