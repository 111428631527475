import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "./ImageSlider.css";
import { EffectCoverflow, Pagination, Navigation } from "swiper/modules";

const ImageSlider = (props) => {
  return (
    <div className="img-slider-container">
      <h1 className="heading">{props.title}</h1>
      <Swiper
        effect={"coverflow"}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: ".swiper-pagination", clickable: true }}
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
          clickable: true,
        }}
        modules={[EffectCoverflow, Pagination, Navigation]}
        className="swiper_container"
      >
        <SwiperSlide>
          <img src={props.img1} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img2} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img3} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img4} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img5} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img6} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img7} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img8} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img9} alt="slide_image" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={props.img10} alt="slide_image" />
        </SwiperSlide>

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <FaArrowLeft />
          </div>
          <div className="swiper-button-next slider-arrow">
            <FaArrowRight />
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
  );
};

export default ImageSlider;
