import img_1 from "./assets/nearby-famous-place-img/Chandrashila-Trek.jpg";
import img_2 from "./assets/nearby-famous-place-img/Deoria-Tal.jpg";
import img_3 from "./assets/nearby-famous-place-img/HD_wallpaper_valley_of_flowers_chamoli_district_india_sunrise_landscape.jpg";
import img_4 from "./assets/nearby-famous-place-img/Makkumath-A-Winter-Seat-of-Tungnath.jpg";
import img_5 from "./assets/nearby-famous-place-img/Triyuginarayan_Temple.jpg";
import img_6 from "./assets/nearby-famous-place-img/Tung-Nath-Temple-1024x643.jpg";
import img_7 from "./assets/nearby-famous-place-img/Ukhimath.jpg";
import img_8 from "./assets/nearby-famous-place-img/Gopinath-1.jpg";
import img_9 from "./assets/nearby-famous-place-img/Madhyamaheshwar.jpeg";

export const famousPlaceData = [
  {
    id: 1,
    title: "Chandrashila Trek",
    description:
      "Chandrashila is a summit in the Garhwal Himalayas of Uttarakhand, India, standing at an elevation of approximately 4,000 meters (13,123 feet). It is renowned for offering stunning panoramic views of the Himalayan peaks, including Nanda Devi, Trishul, Kedar Peak, Bandarpunch, and Chaukhamba. The summit is accessible via a popular trekking route, which typically starts from the village of Chopta and passes through the revered Tungnath Temple, the highest Shiva temple in the world. Chandrashila is not only a favored destination for trekking enthusiasts but also holds significant spiritual importance in Hindu mythology.",
    img: `${img_1}`,
    webTitle: "Chandrashila",
  },
  {
    id: 2,
    title: "Deoria Tal",
    description:
      "Deoria Tal is a mesmerizing high-altitude lake nestled at 2,438 meters (7,999 feet) in the Garhwal Himalayas of Uttarakhand, India. Its crystal-clear waters mirror the majestic Chaukhamba peaks, creating a breathtaking panorama. Accessible via a short, scenic trek from Sari village, this tranquil spot is a paradise for trekkers, nature lovers, and photographers. The serene ambiance and lush surroundings make it an ideal escape from the hustle and bustle. Steeped in mythology, Deoria Tal is believed to be the site where the Pandavas were tested by Yaksha in the Mahabharata. Whether seeking adventure or peace, Deoria Tal offers an unforgettable experience.",
    img: `${img_2}`,
    webTitle: "Deoria_Tal",
  },
  {
    id: 3,
    title: "Valley of Flowers",
    description:
      "The Valley of Flowers, nestled in the Western Himalayas of Uttarakhand, India, is a UNESCO World Heritage site renowned for its stunning natural beauty. At an altitude of about 3,658 meters (12,000 feet), this enchanting valley bursts into a riot of colors every monsoon, with over 500 species of wildflowers in bloom. Trekking through this paradise, visitors are treated to breathtaking views of alpine meadows, snow-capped peaks, and cascading waterfalls. The valley's vibrant flora and serene ambiance create a magical escape for nature lovers and adventurers alike. Its rich biodiversity also includes rare and endangered species, making it a botanist's dream destination.",
    img: `${img_3}`,
    webTitle: "Valley_of_Flowers_National_Park",
  },
  {
    id: 4,
    title: "Makkumath – Winter Seat of Tungnath",
    description:
      "Makkumath, nestled in the serene landscapes of Uttarakhand, India, serves as the winter seat of the revered Tungnath Temple. Located at an elevation of 1,800 meters (5,905 feet), this quaint village becomes a spiritual hub when heavy snowfall makes Tungnath inaccessible. Makkumath offers a peaceful retreat with its picturesque views, dense forests, and tranquil ambiance. Pilgrims and nature enthusiasts flock here to experience its sacred charm and stunning natural beauty. The village is also a gateway for treks to nearby attractions, making it a perfect blend of spirituality and adventure. Makkumath's rich cultural heritage and serene surroundings provide a rejuvenating escape from the bustle of city life.",
    img: `${img_4}`,
    webTitle: "Tungnath",
  },
  {
    id: 5,
    title: "Triyuginarayan Temple",
    description:
      "Triyuginarayan Temple, nestled in the picturesque village of Triyuginarayan in Uttarakhand, India, is a sacred site steeped in mythological significance. At an altitude of 1,980 meters (6,500 feet), this ancient temple is believed to be the divine venue of Lord Shiva and Goddess Parvati's celestial wedding. The temple is renowned for its eternal flame, which is said to have been burning since their union. Visitors are captivated by its spiritual ambiance, stunning Himalayan backdrop, and the serene setting. The temple's rich history and religious significance, coupled with the tranquil beauty of the surrounding landscape, make it a compelling destination for pilgrims and travelers alike.",
    img: `${img_5}`,
    webTitle: "Triyuginarayan_Temple",
  },

  {
    id: 6,
    title: "Tungnath Temple",
    description:
      "Tungnath Temple, situated at an impressive altitude of 3,680 meters (12,073 feet) in Uttarakhand, India, is the highest Shiva temple in the world. This ancient temple, part of the sacred Panch Kedar pilgrimage, is enveloped by the breathtaking Garhwal Himalayas. Trekkers and devotees alike are drawn to its spiritual significance and stunning panoramic views of snow-capped peaks. The journey to Tungnath involves a scenic trek through lush meadows and vibrant rhododendron forests. Beyond its spiritual allure, the nearby Chandrashila summit offers unmatched vistas, enhancing the temple's mystique. Tungnath Temple's serene atmosphere and spectacular surroundings make it an unforgettable destination.",
    img: `${img_6}`,
    webTitle: "Tungnath",
  },

  {
    id: 7,
    title: "Ukhimath",
    description:
      "Ukhimath, nestled in the picturesque Rudraprayag district of Uttarakhand, India, is a charming hill town known for its spiritual significance and natural beauty. At an elevation of 1,311 meters (4,301 feet), Ukhimath serves as the winter seat for the deities of Kedarnath and Madhyamaheshwar temples. The town offers stunning panoramic views of the snow-capped Himalayan peaks, lush green valleys, and serene surroundings. Its rich cultural heritage and numerous ancient temples attract pilgrims and history enthusiasts alike. The tranquil ambiance and scenic beauty make Ukhimath a perfect retreat for those seeking peace and rejuvenation amidst nature's splendor.",
    img: `${img_7}`,
    webTitle: "Ukhimath",
  },
  {
    id: 8,
    title: "Gopinath Temple",
    description:
      "Gopinath Temple in Gopeshwar, Uttarakhand, India, is a captivating blend of spirituality and architectural splendor. Dedicated to Lord Shiva as Gopinath, this ancient temple boasts intricate stone carvings and exquisite craftsmanship. Nestled amidst the scenic beauty of the Garhwal Himalayas, the temple offers a serene ambiance for devotees and tourists alike. Its historical significance and religious importance draw pilgrims seeking blessings and solace. Gopinath Temple's tranquil atmosphere and panoramic mountain views make it a must-visit destination for spiritual seekers and lovers of heritage. It's a divine sanctuary for soulful introspection..",
    img: `${img_8}`,
    webTitle: "Gopinath_Mandir",
  },
  {
    id: 9,
    title: "Madhyamaheshwar",
    description:
      "Madhyamaheshwar, nestled in the Garhwal region of Uttarakhand, India, is a serene haven of spirituality and natural beauty. Situated at an altitude of 3,497 meters (11,473 feet), it is home to the sacred Madhyamaheshwar Temple, dedicated to Lord Shiva. Surrounded by majestic peaks and lush meadows, this divine site offers a tranquil retreat for pilgrims and trekkers alike. The journey to Madhyamaheshwar is a scenic trek through verdant forests and alpine landscapes, providing breathtaking vistas at every turn. With its spiritual aura and stunning surroundings, Madhyamaheshwar enchants visitors seeking solace and adventure in the lap of the Himalayas.",
    img: `${img_9}`,
    webTitle: "Madhyamaheshwar",
  },
];
