import React from "react";
import "./About.css";

const About = () => {
  return (
    <>
      <div className="about_container">
        <section className="first_container">
          <h1>About Us</h1>
          <p className="greeting">Welcome to Apnu Ghar Home Stay</p>
          <p className="address">
            <span>Address: </span> Makku Math, Rudraprayag, Chopta Road
          </p>

          <p className="about_hotel">
            Nestled amidst the serene landscapes of Rudraprayag, Apnu Ghar Home
            Stay offers a perfect retreat from the hustle and bustle of city
            life. Located on the picturesque Chopta Road, our home stay is
            designed to provide you with the utmost comfort and a homely
            experience.
          </p>
        </section>

        <section className="second_container">
          <h3>Our Accommodation:</h3>
          <ul>
            <li>
              <span>Room Price: </span> ₹1700 per room
            </li>
            <li>
              <span>Total Rooms: </span>6 single rooms with attached bathrooms
            </li>
            <li>
              <span>Amenities: </span>Each room is furnished with a larger
              double bed, ensuring a restful night's sleep. Enjoy the warmth of
              a cozy bonfire, and the convenience of a geyser for hot showers.
              We provide a tranquil environment with no traffic noise, allowing
              you to fully relax and unwind.
            </li>
          </ul>
        </section>

        <section className="third_container">
          <h3>Dining:</h3>
          <p>
            Start your day with a delicious breakfast priced at just ₹200 per
            plate. Savor the flavors of our freshly prepared meals, crafted to
            give you a taste of home.
          </p>
        </section>

        <section className="fourth_container">
          <h3>Facilities:</h3>
          <ul>
            <li>
              <span>Free Parking: </span>Hassle-free parking facilities for all
              our guests.
            </li>
            <li>
              <span>Network Connectivity: </span>Stay connected with excellent
              network coverage from Jio, Airtel, and VI.
            </li>
          </ul>
        </section>

        <section className="fifth_container">
          <h3>Explore Nearby Attractions:</h3>
          <p>
            Our prime location offers easy access to several stunning
            attractions:
          </p>

          <ul>
            <li>
              <span>Ukhimath: </span>A town of temples and rich cultural
              heritage.
            </li>
            <li>
              <span>Chopta Tungnath: </span>Known as the 'Mini Switzerland of
              India', perfect for trekking and exploring nature.
            </li>
            <li>
              <span>Deoria Tal: </span>A pristine lake offering breathtaking
              views and serene surroundings.
            </li>
          </ul>
        </section>
        <section className="sixth_container">
          <p>
            At Apnu Ghar Home Stay, we pride ourselves on providing personalized
            service and a warm welcome to each guest. Our friendly staff is
            dedicated to making your stay memorable and comfortable, treating
            you like family from the moment you arrive.
          </p>
          <p>
            Whether you're here for adventure or tranquility, Apnu Ghar Home
            Stay is your ideal destination. Come and experience the charm,
            comfort, and hospitality that we have to offer. We look forward to
            hosting you and making your stay a delightful experience.
          </p>
        </section>
      </div>
    </>
  );
};

export default About;
