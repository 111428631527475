import React from "react";
import "./Reviews.css";

const Reviews = (props) => {
  return (
    <div class="container mx-auto">
      <section class="text-gray-600 body-font">
        <div class="container px-5 py-12 mx-auto">
          <h2 class="text-3xl font-medium title-font text-gray-900 mb-12 text-center dark:text-purple-600">
            Testimonials
          </h2>
          <div class="flex flex-wrap -m-4">
            <div class="p-4 md:w-1/2 w-full">
              <div class="h-full bg-gray-100 p-8 rounded dark:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p class="leading-relaxed mb-6 dark:text-gray-300">
                  {/* Apnu Ghar Home Stay offered more than just comfortable
                  accommodations; it provided an authentic experience of the
                  Garhwali culture and the magic of the Himalayas. It was a
                  place where I could truly unwind, reconnect with nature, and
                  create memories that will last a lifetime. */}
                  {props.review1}
                </p>
                <span class="inline-flex items-center">
                  <span class="flex-grow flex flex-col pl-4">
                    <span class="title-font font-medium text-gray-900 dark:text-white">
                      {/* Mohit Kumar */}
                      {props.guest1}
                    </span>
                    <span class="text-gray-500 text-sm dark:text-gray-400">
                      Guest
                    </span>
                  </span>
                </span>
              </div>
            </div>
            <div class="p-4 md:w-1/2 w-full">
              <div class="h-full bg-gray-100 p-8 rounded dark:bg-gray-700">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  class="block w-5 h-5 text-gray-400 mb-4"
                  viewBox="0 0 975.036 975.036"
                >
                  <path d="M925.036 57.197h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.399 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l36 76c11.6 24.399 40.3 35.1 65.1 24.399 66.2-28.6 122.101-64.8 167.7-108.8 55.601-53.7 93.7-114.3 114.3-181.9 20.601-67.6 30.9-159.8 30.9-276.8v-239c0-27.599-22.401-50-50-50zM106.036 913.497c65.4-28.5 121-64.699 166.9-108.6 56.1-53.7 94.4-114.1 115-181.2 20.6-67.1 30.899-159.6 30.899-277.5v-239c0-27.6-22.399-50-50-50h-304c-27.6 0-50 22.4-50 50v304c0 27.601 22.4 50 50 50h145.5c-1.9 79.601-20.4 143.3-55.4 191.2-27.6 37.8-69.4 69.1-125.3 93.8-25.7 11.3-36.8 41.7-24.8 67.101l35.9 75.8c11.601 24.399 40.501 35.2 65.301 24.399z"></path>
                </svg>
                <p class="leading-relaxed mb-6 dark:text-gray-300">
                  {/* My recent stay at Apnu Ghar Home Stay in Chopta was an
                  unforgettable experience. Nestled amidst the breathtaking
                  beauty of the Himalayas, this homestay offered much more than
                  just a place to sleep. The warm hospitality of the hosts made
                  me feel right at home from the moment I arrived. */}
                  {props.review2}
                </p>
                <span class="inline-flex items-center">
                  <span class="flex-grow flex flex-col pl-4">
                    <span class="title-font font-medium text-gray-900 dark:text-white">
                      {/* Rakesh Shetty */}
                      {props.guest2}
                    </span>
                    <span class="text-gray-500 text-sm dark:text-gray-400">
                      Guest
                    </span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Reviews;
